<template>
	<div>
		<slot />
	</div>
</template>

<script setup>
useHead({
	meta: [{ name: 'robots', content: 'noindex, nofollow' }],
});
</script>
